<template>
  <div class="infomationDetail main_container">
    <Header></Header>
    <BaseBanner :banner="'enterprise'"></BaseBanner>
    <div class="containter_content">
      <div class="infoDetail_content_center">
        <div class="infoDetail_title" @click="check">{{$route.query.info.title}}</div>
        <div class="infoDetail_time">发表时间：{{ $route.query.info.time }}</div>
      </div>
      <div class="infoDetail_message" v-html="$route.query.info.description">
      </div>
       <!-- <iframe src="jsp/s/ZmyP-2cOtlvM2-zlKU6IIg"  width="100%" height="1200" id="iframename" name="iframename"></iframe> -->
      <div class="infoDetail_share">
        分享到:
        <img src="@/assets/img/share/QQ.png" alt="" @click="shareBlog('qq')">
        <img src="@/assets/img/share/QQ空间.png" alt="" @click="shareBlog('qqroom')">
        <img src="@/assets/img/share/social-tieba.png" alt="" @click="shareBlog('tieba')">
        <img src="@/assets/img/share/微信.png" alt="" @click="shareBlog('wechat')">
        <img src="@/assets/img/share/微博.png" alt="" @click="shareBlog('weibo')">
        <img src="@/assets/img/share/豆瓣.png" alt="" @click="shareBlog('douban')">
      </div>
    </div>
    <el-dialog
        title="微信分享"
        :visible.sync="centerDialogVisible"
        width="25%"
        top="25vh"
        :append-to-body='true'
        left>
      <shareWechat></shareWechat>
    </el-dialog>
    <Footer></Footer>
  </div>
</template>

<script>
import BaseBanner from '@/components/BaseBanner'
import shareWechat from "@/components/ShareWechat.vue";
import Header from '@/components/common/Header';
import Footer from '@/components/common/Footer';
export default {
  name: 'infomationDetail',
  components: { BaseBanner,shareWechat,Header,Footer },
  data(){
    return{
      shareUrl: window.location.href,
      title:'',
      description:'',
      centerDialogVisible:false,
    }
  },
  created() {

  },
  methods:{
    check(){
      console.log(this.$route)
    },
    shareBlog(name){
      if(name=='qqroom'){
        window.open(
            "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=" +
            encodeURIComponent(this.shareUrl) +
            "&title=" +
            this.$route.query.info.title +
            "&summary=" +
            this.$route.query.info.description
        );
      }else if(name=='weibo'){
        window.open(
            "http://service.weibo.com/share/share.php?url=" +
            encodeURIComponent(this.shareUrl) +
            "&title=" +
            this.$route.query.info.title
        );
      }else if(name=='wechat'){
        this.centerDialogVisible = true
      }else if(name=='qq'){
        window.open(
            "http://connect.qq.com/widget/shareqq/index.html?url=" +
            encodeURIComponent(this.shareUrl) +
            "&title=" +
            this.$route.query.info.title
        );
      }else if(name=='douban'){
        // window.open("http://www.douban.com/recommend/?url=" + encodeURIComponent(this.shareUrl) + "&title=" + this.$route.query.id.title);
        this.$message.warning('该域名暂时无法分享，请联系管理员')
      }else if(name=='tieba'){
        this.$message.warning('该域名暂时无法分享，请联系管理员')
        // window.open(
        //     'http://tieba.baidu.com/f/commit/share/openShareApi?title='+this.$route.query.id.title+'&url='+ encodeURIComponent(this.shareUrl) +'&content=utf-8&sourceUrl='+ encodeURIComponent(this.shareUrl)
        // );
      }
    },
    shareToWeChat() {
      // localStorage.setItem("shareUrl", this.shareUrl); // 存储当前页面地址，前端页面直接根据URL链接生成二维码
      // let url = location.href.split("#")[0] + "#/shareWechat"; // 注意：#/Share/toWechat: 自己定义的路由
      // window.open(url);
    },
  }
}
</script>

<style lang="scss" scoped>
.infomationDetail {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 0;

  .containter_content {
    width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
    color: #676767;
    line-height: 26px;
    font-size: 14px;

    .infoDetail_content_center {
      margin: 0 10px;
      padding: 20px 0;
      border-bottom: 1px solid rgb(237, 232, 232);

      .infoDetail_title {
        text-align: left;
        color: #333333;
        font-size: 25px;
        margin-bottom: 20px;
      }

      .infoDetail_time {
        color: #adadad;
      }
    }

    .infoDetail_message {
      margin: 0 10px;
      padding: 20px 0;
      border-bottom: 1px solid rgb(237, 232, 232);
      color: #000;
      font-size: 15px;
    }

    .infoDetail_share {
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
      align-items: center;

      img {
        width: 33px;
        height: 33px;
        margin-left: 15px;
      }
    }
  }
}</style>